import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  notifications: {
    loading: false,
    data: null,
  },
  selectedNotification: {
    loading: false,
    data: null,
  },
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetch: (state, action) => {

    },
    fetchId: (state, action) => {

    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
    setNotifications(state, action) {
      const {payload} = action
      state.notifications.data = payload.data
    },
    setSelectedNotification(state, action) {
      const {payload} = action
      state.selectedNotification.data = payload.data
    },
  },
})
const notificationsReducer = notificationsSlice.reducer

export {notificationsSlice, notificationsReducer}
