import {format, subDays} from "date-fns";
import Logo from "src/components/logo";

export const notificationsTypes = {
  ["system"]: {
    listAvatar: <Logo type='logo32' />,
    itemAvatar: <Logo type='logo24' />,
    label: 'Системные'
  },
}

export const NOTIFICATIONS_DEFAULT_RANGE_DATE = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }
]

export const NOTIFICATIONS_STATUS_FILTERS = [{
  value: "done",
  label: "Прочитаные",
}, {
  value: "new",
  label: "Новые",
}]

export const NOTIFICATIONS_TYPE_FILTERS = [{
  value: "system",
  label: "Системные",
}, {
  value: "rejected",
  label: "Need to change",
}]

export const NOTIFICATIONS_DATE_FILTERS = [{
  value: "day-1",
  label: "Сегодня",
  params: {
    created_at__date: format(new Date(), 'yyyy-MM-dd'),
  }
}, {
  value: "day-3",
  label: "3 дня",
  params: {
    date_to: format(new Date(), 'yyyy-MM-dd'),
    date_from: format(subDays(new Date(), 3), 'yyyy-MM-dd'),
  }
}, {
  value: "day-7",
  label: "Неделя",
  params: {
    date_to: format(new Date(), 'yyyy-MM-dd'),
    date_from: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
  }
}, {
  value: "day-30",
  label: "Месяц",
  params: {
    date_to: format(new Date(), 'yyyy-MM-dd'),
    date_from: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
  }
}]

export const getDateParams = (date, dateRange) => {
  if (date && !dateRange) {
    return {
      date: NOTIFICATIONS_DATE_FILTERS.find(({value}) => date === value)?.params
    }
  }
  if (dateRange && !date) {
    const start = format(dateRange.startDate, 'yyyy-MM-dd')
    const end = format(dateRange.endDate, 'yyyy-MM-dd')
    if (start === end) {
      return {
        dateRange: {created_at__date: start}
      }
    } else {
      return {
        dateRange: {
          date_to: end,
          date_from: start
        }
      }
    }
  }
}
