import {put, takeLatest} from "redux-saga/effects"
import {notificationsSlice} from './notificationsSlice'
import {network} from '../../utils/network'

function* fetch({payload}) {
  yield put(notificationsSlice.actions.setLoading({type: 'notifications', loading: true}))

  try {
    const response = yield network.axios({
      method: 'GET',
      url: '/notification/',
      params: {
        status: payload.status,
        notification_type: payload.notification_type,
        text: payload.text,
        date_from: payload.date_from,
        date_to: payload.date_to,
      }
    })

    yield put(notificationsSlice.actions.setNotifications({
      data: response.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(notificationsSlice.actions.setLoading({type: 'notifications', loading: false}))
  }
}

function* fetchId({payload}) {
  yield put(notificationsSlice.actions.setLoading({type: 'selectedNotification', loading: true}))
  yield put(notificationsSlice.actions.setSelectedNotification({data: null}))

  try {
    const response = yield network.axios({
      method: 'GET',
      url: `/notifications/${payload.id}/`,
    })

    yield put(notificationsSlice.actions.setSelectedNotification({
      data: response?.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(notificationsSlice.actions.setLoading({type: 'selectedNotification', loading: false}))
  }
}


export function* notificationsSagas() {
  yield takeLatest(notificationsSlice.actions.fetch.type, fetch)
  yield takeLatest(notificationsSlice.actions.fetchId.type, fetchId)
}
