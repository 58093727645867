import {alpha, Box, Button, IconButton, List, Stack, Tab, Tabs, TextField, Typography} from '@mui/material'
import React from 'react'
import DialogBody from 'src/components/dialog-body'
import DialogHeader from 'src/components/dialog-header'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Scrollbar from '../scrollbar'
import NotificationCustomItem from 'src/layouts/_common/notifications-popover/notification-custom-item'
import Iconify from 'src/components/iconify/iconify';
import TuneIcon from '@mui/icons-material/Tune';
import SettingsIcon from '@mui/icons-material/Settings';
import AppliedFilters from '../filters/AppliedFilters';
import LoadingDataSmall from '../loading-data-small/LoadingDataSmall';
import NotificationsEmpty from './NotificationsEmpty';
import {groupNotificationsByDate} from 'src/utils/notifications-group';
import {format, parse} from 'date-fns';
import {ru} from 'date-fns/locale';

const HeaderItems = ({showReadAll, readAll, title}) => (
  <Stack
    direction='row'
    justifyContent='space-between'
    gap={1}
    alignItems='center'
    mb={2}
  >
    <Typography color='#454F5B' variant='h5'>
      {title}
    </Typography>
    {showReadAll && (
      <Button
        variant='text'
        sx={{
          color: '#454F5B'
        }}
        onClick={readAll}
      >
        Отметить все прочитанным
      </Button>
    )}
  </Stack>
)

const iconButtonSx = [
  {
    backgroundColor: "#E8ECF1",
    height: 52,
    width: 52,
    borderRadius: "8px",
    '&:hover': {
      backgroundColor: (theme) =>
        alpha(theme.palette.grey[500], 0.24),
    },
  }
]

export default function NotificationsList(props) {
  const {
    close,
    notifications,
    setSelectedNotification,
    tab,
    textFilter,
    onClearText,
    onChangeTabs,
    onChangeText,
    showFilters,
    appliedFilters,
    onDeleteAppliedFilters,
    loading,
    onResetEmpty,
    readAll,
    unreadIds
  } = props

  const groupedNotifications = groupNotificationsByDate(notifications)

  const today = groupedNotifications?.today?.length > 0 && (
    <Box mb={3}>
      <HeaderItems
        title='Сегодня'
        unreadIds={unreadIds}
        readAll={readAll}
        showReadAll={unreadIds?.length > 0}
      />
      <List disablePadding>
        {groupedNotifications?.today?.map((notification) => (
          <NotificationCustomItem
            key={notification.id}
            notification={notification}
            setSelectedNotification={setSelectedNotification}
          />
        ))}
      </List>
    </Box>
  )

  const yesterday = groupedNotifications?.yesterday?.length > 0 && (
    <Box mb={3}>
      <HeaderItems
        title='Вчера'
        unreadIds={unreadIds}
        readAll={readAll}
        showReadAll={(unreadIds?.length > 0) && (groupedNotifications?.today?.length === 0)}
      />
      <List disablePadding>
        {groupedNotifications?.yesterday?.map((notification) => (
          <NotificationCustomItem
            key={notification.id}
            notification={notification}
            setSelectedNotification={setSelectedNotification}
          />
        ))}
      </List>
    </Box>
  )

  const older = groupedNotifications?.older && (
    <>
      {Object.keys(groupedNotifications?.older)
        .sort((a, b) => new Date(b) - new Date(a))
        .map((date, index) => (
          <Box mb={3}>
            <HeaderItems
              title={format(parse(date, 'dd MMMM', new Date()), 'dd MMMM', {locale: ru})}
              unreadIds={unreadIds}
              readAll={readAll}
              showReadAll={
                (unreadIds?.length > 0) &&
                (groupedNotifications?.today?.length === 0 && groupedNotifications?.yesterday?.length === 0) &&
                (index === 0)
              }
            />
            {groupedNotifications?.older?.[date] && (
              <List disablePadding>
                {groupedNotifications?.older?.[date]?.map((notification) => (
                  <NotificationCustomItem
                    key={notification.id}
                    notification={notification}
                    setSelectedNotification={setSelectedNotification}
                  />
                ))}
              </List>
            )}
          </Box>
        ))}
    </>
  )

  const renderList = notifications?.length > 0 && (
    <Scrollbar>
      {today}
      {yesterday}
      {older}
    </Scrollbar>
  );

  return (
    <>
      <DialogHeader
        close={close}
      >
        <IconButton>
          <NotificationsNoneIcon sx={{fill: "#212B36"}} />
        </IconButton>
        <Typography align='center' variant="h4" sx={{mr: 'auto'}}>
          Уведомления
        </Typography>
      </DialogHeader>
      <DialogBody sx={{backgroundColor: "#fff"}}>

        <Tabs
          value={tab}
          onChange={onChangeTabs}
          style={{borderBottom: '1px solid #E8ECF1'}}
          variant="scrollable"
        >
          <Tab label="Все" value={"all"} />
          <Tab label="Новые" value={"new"} />
          <Tab label="Прочитанные" value={"done"} />
        </Tabs>

        <Stack direction='row' sx={{width: '100%', gap: '12px'}} mt={3} mb={3}>
          <Box sx={{position: 'relative', width: '100%'}} >
            <TextField
              label={"Найти в истории"}
              style={{height: "52px"}}
              InputProps={{
                style: {
                  height: "52px",
                  width: "100%",
                }
              }}
              fullWidth
              onChange={onChangeText}
              value={textFilter || ""}
            />
            {!!!textFilter || (
              <IconButton
                sx={{
                  position: "absolute",
                  zIndex: 11,
                  right: "16px",
                  top: "8px",
                }}
                aria-label="toggle password visibility"
                onClick={onClearText}
                edge="end"
              >
                <Iconify icon="solar:close-circle-bold" />
              </IconButton>
            )}
          </Box>
          <IconButton sx={iconButtonSx} onClick={showFilters}>
            <TuneIcon />
          </IconButton>
          {/* <IconButton sx={iconButtonSx}>
            <SettingsIcon />
          </IconButton> */}
        </Stack>
        {!!appliedFilters?.length && (
          <Box mb={3}>
            <AppliedFilters
              filters={appliedFilters}
              onDelete={onDeleteAppliedFilters}
            />
          </Box>
        )}
        {loading ? (
          <LoadingDataSmall loading={loading} />
        ) : notifications?.length === 0 ? (
          <NotificationsEmpty onReset={onResetEmpty} hasFilters={appliedFilters.length > 0} />
        ) : (
          <>
            {renderList}
          </>
        )}
      </DialogBody>
    </>
  )
}

